






















































































































































































































































































































































































































import { Api, canMerchOrderTransition, canDeleteMerchOrder, EditNoteRequest, MerchPurchase, MerchPurchaseFilters, MerchPurchaseItem, MerchPurchaseStatus, PagedResults, request, TableState, canGenerateInvoice, MerchBundleStub, merchBundleStubFromPurchaseItem } from '@/edshed-common/api'
import moment from 'moment'
import Component from 'vue-class-component'
import ComponentHelper from '@/mixins/ComponentHelper'
import debounce from 'lodash/debounce'
import Note from '@/components/views/components/Note.vue'
import NoteSet from '@/components/views/components/NoteSet.vue'
import EditMerchInvoice from '@/components/views/components/EditMerchInvoice.vue'
import { Mixins } from 'vue-property-decorator'
import BundleView from '@/edshed-common/components/BundleView.vue'
import { SalesSourcePersons, SalesSourceDepts } from '../../edshed-common/api/types/sales'

@Component({
  name: 'MerchSales',
  components: { Note, NoteSet, EditMerchInvoice, BundleView }
})
export default class MerchSales extends Mixins(ComponentHelper) {
  loading: boolean = false

  salesData: PagedResults<MerchPurchase> = {
    items: [],
    total: 0
  }

  table: TableState = {
    page: 1,
    perPage: 10,
    sort: 'timestamp',
    dir: 'desc',
    term: ''
  }

  filters: MerchPurchaseFilters = {
    school_id: undefined,
    status: [],
    unpaid: false
  }

  schoolSearchResults: Array<any> = []
  isFetching: boolean = false

  addingNote: true | null | number = null

  MerchPurchaseStatus = MerchPurchaseStatus

  bundleToDisplay: MerchBundleStub | null = null

  canMerchOrderTransition = canMerchOrderTransition

  canDeleteMerchOrder = canDeleteMerchOrder

  canGenerateInvoice = canGenerateInvoice

  editingMerchOrder: MerchPurchase | null = null

  persons = SalesSourcePersons
  depts = SalesSourceDepts

  mounted () {
    moment.locale(this.$store.state.user.locale)

    if (!this.$store.state.user.superuser) {
      this.$router.push('/noaccess')
    } else {
      this.getMerchSalesInfo()
    }
  }

  pageChanged (page: number) {
    this.table.page = page

    this.getMerchSalesInfo()
  }

  sortingChanged (col: string, dir: 'asc' | 'desc') {
    this.table.sort = col
    this.table.dir = dir

    this.getMerchSalesInfo()
  }

  async getInvoice (invoiceId: number) {
    try {
      this.loading = true
      const res = await Api.getMerchInvoice(invoiceId)
      if (res) {
        window.location.href = res
      } else {
        throw new Error('No invoice')
      }
    } catch (err: unknown) {
      this.$buefy.toast.open({ type: 'is-danger', position: 'is-bottom', message: 'Could not load the invoice!' })
    } finally {
      this.loading = false
    }
  }

  openInvoice (url: string) {
    window.open(url, '_blank')?.focus()
  }

  onInvoiceUpdated (invoice: MerchPurchase) {
    Object.assign(this.editingMerchOrder, invoice)

    // prompt to finalise
    if (canMerchOrderTransition(invoice, 'finalised')) {
      this.setFinalised(invoice.id)
    }
  }

  searchSchoolsDebounced = debounce((school) => {
    this.searchSchools(school)
  }, 500)

  searchSchools (school: string) {
    if (!school.length) {
      this.schoolSearchResults = []
    }

    this.isFetching = true

    Api.searchSchools(school, false)
      .then((response) => {
        this.schoolSearchResults = response
      })
      .catch((err) => {
        console.log(err)
        this.schoolSearchResults = []
        throw err
      })
      .finally(() => {
        this.isFetching = false
      })
  }

  async getMerchSalesInfo () {
    try {
      this.salesData = await Api.getAllMerchSales({ skip: (this.table.page - 1) * this.table.perPage, take: this.table.perPage, sort: this.table.sort, dir: this.table.dir, term: this.table.term }, this.filters)
    } catch (err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Could not load merch sales',
        position: 'is-bottom',
        type: 'is-danger'
      })
    }
  }

  recreateMerchInvoice (id: number) {
    request('GET', 'superuser/merchsales/invoice/' + id, null)
      .then((_response) => {
        this.getMerchSalesInfo()
      })
      .catch((error) => {
        console.log(error)

        this.$buefy.toast.open({
          duration: 5000,
          message: 'Could not regenerate invoice',
          position: 'is-bottom',
          type: 'is-danger'
        })
      })
  }

  setShipped (id: number) {
    this.$buefy.dialog.confirm({
      title: 'Mark Order Shipped',
      message: 'You are about to mark this order as <b>shipped</b>. This action cannot be undone.',
      confirmText: 'Mark Shipped',
      type: 'is-warning',
      hasIcon: true,
      onConfirm: async () => {
        try {
          const newOrder = await Api.setMerchShipped(id)

          const oldOrder = this.salesData.items.find(s => s.id === id)

          if (oldOrder) {
            Object.assign(oldOrder, newOrder)
          }
        } catch (err: unknown) {
          if (err instanceof Error) {
            this.$buefy.toast.open({
              message: err.message,
              type: 'is-danger',
              position: 'is-bottom',
              duration: 5000
            })
          }
        }
      }
    })
  }

  askShippingCost (id: number) {
    this.$buefy.dialog.prompt({
      message: 'Enter Shipping Cost',
      inputAttrs: {
        type: 'number',
        placeholder: 'Shipping Cost',
        value: '0.00',
        min: 0,
        step: 0.01
      },
      trapFocus: true,
      onConfirm: value => this.setShippingCost(id, parseFloat(value))
    })
  }

  async setShippingCost (id: number, cost: number) {
    try {
      await Api.setMerchShippingCost(id, cost)
      this.getMerchSalesInfo()
    } catch (err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Could not set shipping cost',
        position: 'is-bottom',
        type: 'is-danger'
      })
    }
  }

  setFinalised (id: number) {
    this.$buefy.dialog.confirm({
      title: 'Finalise Invoice',
      message: 'This order is ready to be <b>finalised</b>. This action cannot be undone',
      confirmText: 'Finalise',
      type: 'is-warning',
      hasIcon: true,
      onConfirm: async () => {
        try {
          const newOrder = await Api.setMerchFinalised(id)

          const oldOrder = this.salesData.items.find(s => s.id === id)

          if (oldOrder) {
            Object.assign(oldOrder, newOrder)
          }
        } catch (err: unknown) {
          if (err instanceof Error) {
            this.$buefy.toast.open({
              message: err.message,
              type: 'is-danger',
              position: 'is-bottom',
              duration: 5000
            })
          }
        }
      }
    })
  }

  setVoid (id: number) {
    this.$buefy.dialog.confirm({
      title: 'Mark Order Void',
      message: 'You are about to mark this order as <b>void</b>. This action cannot be undone.',
      confirmText: 'Mark Void',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: async () => {
        try {
          const newOrder = await Api.setMerchVoid(id)

          const oldOrder = this.salesData.items.find(s => s.id === id)

          if (oldOrder) {
            Object.assign(oldOrder, newOrder)
          }
        } catch (err: unknown) {
          if (err instanceof Error) {
            this.$buefy.toast.open({
              message: err.message,
              type: 'is-danger',
              position: 'is-bottom',
              duration: 5000
            })
          }
        }
      }
    })
  }

  setPromised (id: number) {
    this.$buefy.dialog.confirm({
      title: 'Mark Order Confirmed',
      message: 'You are about to mark this order as <b>confirmed</b>. This action cannot be undone.',
      confirmText: 'Mark Confirmed',
      type: 'is-warning',
      hasIcon: true,
      onConfirm: async () => {
        try {
          const newOrder = await Api.setMerchPromised(id)

          const oldOrder = this.salesData.items.find(s => s.id === id)

          if (oldOrder) {
            Object.assign(oldOrder, newOrder)
          }
        } catch (err: unknown) {
          if (err instanceof Error) {
            this.$buefy.toast.open({
              message: err.message,
              type: 'is-danger',
              position: 'is-bottom',
              duration: 5000
            })
          }
        }
      }
    })
  }

  setPaid (id: number) {
    this.$buefy.dialog.confirm({
      title: 'Mark Order Paid',
      message: 'You are about to mark this order as <b>paid</b>. This action cannot be undone.',
      confirmText: 'Mark Paid',
      type: 'is-warning',
      hasIcon: true,
      onConfirm: async () => {
        try {
          const newOrder = await Api.setMerchPaid(id)

          const oldOrder = this.salesData.items.find(s => s.id === id)

          if (oldOrder) {
            Object.assign(oldOrder, newOrder)
          }
        } catch (err: unknown) {
          if (err instanceof Error) {
            this.$buefy.toast.open({
              message: err.message,
              type: 'is-danger',
              position: 'is-bottom',
              duration: 5000
            })
          }
        }
      }
    })
  }

  dateFromServerDate (dt) {
    if (dt === null || dt === '' || !moment(dt).isValid()) {
      return null
    }
    const m = moment(dt)
    // var utcOffset = new Date().getTimezoneOffset()
    // m.add({minutes: utcOffset})
    return m.format('L LT')
  }

  async addNote (message: string, purchaseId: number) {
    const note = await Api.addNote({ note: message, target_type: 'merch_purchase', target_id: purchaseId, parent_id: this.addingNote === true ? null : this.addingNote })

    const purchase = this.salesData.items.find(s => s.id === purchaseId)

    if (!purchase) {
      return
    }

    if (!purchase.notes) {
      purchase.notes = []
    }

    if (note.parent_id === null) {
      purchase.notes.push(note)
    } else {
      const getParent = (notes) => {
        for (const n of notes) {
          if (n.id === note.parent_id) {
            return n.children
          }

          const p = getParent(n.children)

          if (p) {
            return p
          }
        }

        return false
      }

      const parentArray = getParent(purchase.notes)
      parentArray.push(note)
    }

    this.addingNote = null
  }

  async deleteNote (noteId: number, purchaseId: number) {
    const isParent = (notes) => {
      for (const note of notes) {
        if (note.parent_id === noteId) {
          return true
        }

        if (isParent(note.children)) {
          return true
        }
      }

      return false
    }

    if (isParent(this.salesData.items.find(s => s.id === purchaseId)?.notes)) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Cannot delete a note with replies',
        position: 'is-bottom',
        type: 'is-danger'
      })

      return
    }

    await Api.deleteNote(noteId)

    const getParent = (notes) => {
      for (const n of notes) {
        if (n.id === noteId) {
          return notes
        }

        const p = getParent(n.children)

        if (p) {
          return p
        }
      }

      return false
    }
    const parentArray = getParent(this.salesData.items.find(s => s.id === purchaseId)?.notes)

    const index = parentArray.findIndex(n => n.id === noteId)
    parentArray.splice(index, 1)
  }

  async editNote (noteId: number, data: EditNoteRequest, purchaseId: number) {
    await Api.editNote(noteId, data)

    const getNote = (notes) => {
      for (const n of notes) {
        if (n.id === noteId) {
          return n
        }

        const p = getNote(n.children)

        if (p) {
          return p
        }
      }

      return false
    }
    const oldNote = getNote(this.salesData.items.find(s => s.id === purchaseId)?.notes)
    oldNote.note = data.note
  }

  deleteOrder (order: MerchPurchase) {
    if (!canDeleteMerchOrder(order)) {
      return
    }

    this.$buefy.dialog.confirm({
      title: 'Deleting Merch Order',
      message: 'Are you sure you want to <b>delete</b> this order? This action cannot be undone.',
      confirmText: 'Delete Order',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: async () => {
        try {
          await Api.deleteMerchOrder(order.id)
          await this.getMerchSalesInfo()
        } catch (err: unknown) {
          if (err instanceof Error) {
            this.$buefy.toast.open({
              message: err.message,
              type: 'is-danger',
              position: 'is-bottom',
              duration: 5000
            })
          }
        }
      }
    })
  }

  editOrder (order: MerchPurchase) {
    this.editingMerchOrder = order
  }

  async saveSourcePerson (obj: MerchPurchase) {
    try {
      const id: number = obj.id
      if (!obj.source_person) {
        throw new Error('No person to save!')
      }
      const person: SalesSourcePersons = obj.source_person
      await Api.setMerchSourcePerson(id, person)

      this.$buefy.toast.open({
        message: 'Source person saved successfully!',
        type: 'is-success',
        position: 'is-bottom',
        duration: 5000
      })
    } catch (err) {
      this.$buefy.toast.open({
        message: 'Could not save the source person',
        type: 'is-danger',
        position: 'is-bottom',
        duration: 5000
      })
    }
  }

  async saveSourceDept (obj: MerchPurchase) {
    try {
      const id: number = obj.id
      if (!obj.source_dept) {
        throw new Error('No dept to save!')
      }
      const dept: SalesSourceDepts = obj.source_dept
      await Api.setMerchSourceDept(id, dept)

      this.$buefy.toast.open({
        message: 'Source dept saved successfully!',
        type: 'is-success',
        position: 'is-bottom',
        duration: 5000
      })
    } catch (err) {
      this.$buefy.toast.open({
        message: 'Could not save the source dept',
        type: 'is-danger',
        position: 'is-bottom',
        duration: 5000
      })
    }
  }

  displayBundle (bundle: MerchPurchaseItem) {
    this.bundleToDisplay = merchBundleStubFromPurchaseItem(bundle)
  }
}

